<template>
  <svg>
    <g transform="translate(0.5,0.5)" style="visibility: visible">
      <ellipse
        cx="15.98"
        cy="14.96"
        rx="13.600000000000001"
        ry="13.600000000000001"
        fill="#ffffff"
        stroke="#000000"
        stroke-width="1.3"
        pointer-events="all"
      ></ellipse>
    </g>
  </svg>
</template>
<script>
export default {
}
</script>
<style scoped>
.svg-node {
  left: 1px;
  top: 1px;
  width: 32px;
  height: 30px;
  display: block;
  position: relative;
  overflow: hidden;
}
</style>
