<template>
  <svg>
    <g>
      <g transform="translate(0.5,0.5)" style="visibility: visible">
        <rect
          x="0.98"
          y="7.84"
          width="29.4"
          height="12.74"
          fill="none"
          stroke="white"
          pointer-events="stroke"
          visibility="hidden"
          stroke-width="9"
        ></rect>
        <rect
          x="0.98"
          y="7.84"
          width="29.4"
          height="12.74"
          fill="none"
          stroke="none"
          pointer-events="all"
        ></rect>
      </g>
      <g style="">
        <clipPath id="mx-clip-2-9-28-15-0">
          <rect x="2" y="9" width="28" height="15"></rect>
        </clipPath>
        <g
          fill="#000000"
          font-family="Helvetica"
          clip-path="url(https://app.diagrams.net/#mx-clip-2-9-28-15-0)"
          font-size="5.88px"
        >
          <text x="3.92" y="20.66" style="font-size: 16px;">文本</text>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>
