<template>
  <svg>
    <g transform="translate(0.5,0.5)" style="visibility: visible">
      <path
        d="M 15.98 1.36 L 29.58 14.96 L 15.98 28.56 L 2.38 14.96 Z"
        fill="#ffffff"
        stroke="#000000"
        stroke-width="1.3"
        stroke-miterlimit="10"
        pointer-events="all"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>
