<template>
  <svg>
    <g transform="translate(0.5,0.5)" style="visibility: visible">
      <path
        d="M 5.78 6.46 C 5.78 3.64 10.35 1.36 15.98 1.36 C 18.69 1.36 21.28 1.9 23.19 2.85 C 25.11 3.81 26.18 5.11 26.18 6.46 L 26.18 23.46 C 26.18 26.28 21.61 28.56 15.98 28.56 C 10.35 28.56 5.78 26.28 5.78 23.46 Z"
        fill="#ffffff"
        stroke="#000000"
        stroke-width="1.3"
        stroke-miterlimit="10"
        pointer-events="all"
      ></path>
      <path
        d="M 26.18 6.46 C 26.18 9.28 21.61 11.56 15.98 11.56 C 10.35 11.56 5.78 9.28 5.78 6.46"
        fill="none"
        stroke="#000000"
        stroke-width="1.3"
        stroke-miterlimit="10"
        pointer-events="all"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>
