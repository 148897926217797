<template>
  <div class="flow" ref="flow">
    <div class="flow-head">
      <div class="head-left">
        <el-button size="small" icon="el-icon-arrow-left" @click="$router.back()">返回</el-button>
        <div class="head-title">
          <div v-if="!editStatus">{{ flowChartInfo.name }}</div>
<!--          <el-input v-else size="small" ref="nameInput" v-model="flowChartInfo.name" @blur="editStatus=false"></el-input>-->
<!--          <i class="el-icon-edit" v-if="!editStatus" @click=editName()>修改名称</i>-->
        </div>
      </div>
    </div>
    <div class="program-main" v-loading="loading">
      <div class="left" ref="left">
<!--        <div style="position: absolute;width: 100%;height: 100%;left: 0;top: 0;"></div>-->
<!--        <embed style="position: relative;z-index: 2" v-if="pdfFile?.source?.path"  id="iframe" ref="myIframe" :src="pdfFile?.source?.path" width="100%" height="100%" type="application/pdf" />-->
        <ExperimentSource v-if="sourceGroup.length" :sourceGroup="sourceGroup"></ExperimentSource>
        <div v-else style="text-align: center;font-size: 14px;">暂无数据</div>

      </div>
      <div class="line" ref="line" @mousedown="md">
        <i class="el-icon-more"></i>
      </div>
      <div class="right" ref="right" >
        <div class="flow-main" v-show="experimentStatus == 1">
          <div class="main-left">
            <div class="left-title">图形</div>
            <div class="graphical">
              <div class="graphical-item" @mousedown="dragInNode('pro-circle')">
                <IconCircle />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('pro-rect')">
                <IconRect />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('rect-radius')">
                <IconRectRadius />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('actor')">
                <IconActor />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('cylinde')">
                <IconCylinde />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('pro-diamond')">
                <IconDiamond />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('pro-ellipse')">
                <IconEllipse />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('parallelogram')">
                <IconParallelogram />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('pro-text')">
                <IconText />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('triangle')">
                <IconTriangle />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('left-arrow')">
                <IconLeftArrow />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('right-arrow')">
                <IconRightArrow />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('horizontal-arrow')">
                <IconHorizontalArrow />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('up-arrow')">
                <IconUpArrow />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('down-arrow')">
                <IconDownArrow />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('vertical-arrow')">
                <IconVerticalArrow />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('pentagon')">
                <IconPentagon />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('hexagon')">
                <IconHexagon />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('septagon')">
                <IconSeptagon />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('heptagon')">
                <IconHeptagon />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('trapezoid')">
                <IconTrapezoid />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('cross')">
                <IconCross />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('minus')">
                <IconMinus />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('times')">
                <IconTimes />
              </div>
              <div class="graphical-item" @mousedown="dragInNode('divide')">
                <IconDivide />
              </div>
            </div>
          </div>
          <div class="main-right">
            <div class="tool-box">
              <el-button size="mini" @click="selectionSelect()" :type="selectionOpened ? 'warning' : ''">
                <AreaSelect :color="selectionOpened ? 'white' : ''" /> 选区
              </el-button>
              <el-button size="mini" icon="el-icon-minus" @click="flowZoom(false)">缩小</el-button>
              <el-button size="mini" icon="el-icon-refresh" @click="flowResetZoom()">恢复缩放</el-button>
              <el-button size="mini" icon="el-icon-plus" @click="flowZoom(true)">放大</el-button>
              <el-button icon="el-icon-d-arrow-left" size="mini" @click="undo()">上一步</el-button>
              <el-button size="mini" @click="redo()">下一步 <div class="el-icon-d-arrow-right"></div></el-button>
              <div class="margin"></div>
              <el-button size="mini" @click="save()">保存</el-button>
              <el-button size="mini" icon="el-icon-download" @click="download()">下载</el-button>
              <el-button size="mini" :icon="fullscreenStatus ? 'el-icon-circle-close' : 'el-icon-full-screen'"
                         @click="fullscreen()">{{ fullscreenStatus ? '退出全屏' : '全屏' }}</el-button>
            </div>
            <div class="flow-wrap" style="padding: 20px;">
              <div ref="flowChart" class="flowChart"></div>
              <!-- 右侧属性面板 -->
              <PropertyPanel class="diagram-panel" v-if="activeNodes.length > 0 || activeEdges.length > 0"
                             :onlyEdge="activeNodes.length === 0" :elementsStyle="properties" @setStyle="setStyle"
                             @setZIndex="setZIndex" />
            </div>
          </div>
        </div>
        <div class="flow-main-no" v-show="experimentStatus != 1">
          <el-button type="primary" @click="createExperiment">创建实验</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, nextTick, reactive, onBeforeUnmount } from 'vue';
import LogicFlow from "@logicflow/core";
import { Menu, DndPanel, SelectionSelect, Snapshot } from "@logicflow/extension";
import "@logicflow/core/dist/style/index.css";
import "@logicflow/extension/lib/style/index.css";
import utils from "@/utils/tools.js";
import PropertyPanel from './components/PropertyPanel.vue'
import ExperimentSource from "../trainingcamp/components/experimentSource/index.vue";

import IconCircle from './icon/Circle.vue'
import IconRect from './icon/Rect.vue'
import IconRectRadius from './icon/RectRadius.vue'
import IconActor from './icon/Actor.vue'
import IconCylinde from './icon/Cylinde.vue'
import IconDiamond from './icon/Diamond.vue'
import IconEllipse from './icon/Ellipse.vue'
import IconParallelogram from './icon/Parallelogram.vue'
import IconText from './icon/Text.vue'
import IconTriangle from './icon/Triangle.vue'
import IconLeftArrow from './icon/LeftArrow.vue'
import IconRightArrow from './icon/RightArrow.vue'
import IconHorizontalArrow from './icon/HorizontalArrow.vue'
import IconUpArrow from './icon/UpArrow.vue'
import IconDownArrow from './icon/DownArrow.vue'
import IconVerticalArrow from './icon/VerticalArrow.vue'
import IconPentagon from './icon/Pentagon.vue'
import IconHexagon from './icon/Hexagon.vue'
import IconSeptagon from './icon/Septagon.vue'
import IconHeptagon from './icon/Heptagon.vue'
import IconTrapezoid from './icon/Trapezoid.vue'
import IconCross from './icon/Cross.vue'
import IconMinus from './icon/Minus.vue'
import IconTimes from './icon/Times.vue'
import IconDivide from './icon/Divide.vue'
import AreaSelect from './icon/AreaSelect.vue'

import { registerCustomElement } from './node'

LogicFlow.use(Menu);
LogicFlow.use(DndPanel);
LogicFlow.use(SelectionSelect);
LogicFlow.use(Snapshot);

const instance = getCurrentInstance().proxy;

const flowChartInfo=reactive({
  name:"",
  id:""
});

flowChartInfo.name=instance.$route.query.flowChartName;
flowChartInfo.id=instance.$route.query.flowChartId;

let info = ref({});
let experimentUser = ref({});
let position = ref({
  x:0,
  move:false,
});

// 拖动中间线条改变两侧大小
const md = function(e) {
  position.value.x = e?.target?.offsetLeft || 0;
  position.value.move = true;
};
const mv = function(e) {
  if (!position.value.move) return;
  position.value.x = e.pageX;
  instance.$refs.left.style.width = `${e.pageX}px`;
  instance.$refs.right.style.width = "0px";
};
const mp = function() {
  position.value.move = false;
};

onMounted(()=>{
  window.addEventListener("mousemove", mv);
  window.addEventListener("mouseup", mp);
  getExperimentUser();
})

onBeforeUnmount(()=>{
  window.removeEventListener("mousemove", mv);
  window.removeEventListener("mouseup", mp);
  save();
})

let pdfFile = ref({});
let loading = ref(true);
let sourceGroup = ref([]);
const getDetail = ()=>{
  let params = {
    id:flowChartInfo.id
  }
  loading.value = true;
  instance.api.dataset.experimentDetail(params).then((res)=>{
    loading.value = false;
    info.value = res.info;
    let arr = res.info?.resource.filter((item)=>{
      return item.source_type == 3
    });
    pdfFile.value = arr.length ? arr[0] : {};
    sourceGroup.value = res.source_group;
  }).catch((err)=>{
    loading.value = false;
  })
};
getDetail();
let experimentStatus = ref('0');
let experimentUserId = ref('');
const getExperimentUser = ()=>{
  let params = {
    experiment_id:flowChartInfo.id
  }

  instance.api.dataset.experimentUser(params).then((res)=>{
    experimentUserId.value = res.info?.id;
    if(res?.info?.status == 1){
      // this.getInfo();

      initFlowChart(res.info.process_data);

    }
    experimentStatus.value = res?.info?.status || '0';
  })
};



const editStatus=ref(false);

const nameInput=ref(null);
function editName(){
  editStatus.value=true;
  nextTick(()=>{
    nameInput.value.focus();
  });
}

const flowChart = ref(null);
const lf = ref(null);
// 初始化流程图
function initFlowChart(flowData) {

  lf.value = new LogicFlow({
    container: flowChart.value,
    grid: true,
    keyboard: {
      enabled: true
    },
    metaKeyMultipleSelected:true,
    overlapMode: 1,
  });
  registerCustomElement(lf.value);
  lf.value.setDefaultEdgeType('pro-polyline');
  if(flowData){
    const data=JSON.parse(decodeURIComponent(flowData));
    console.log("流程图数据",data);
    lf.value.render(data);
  }else{
    console.log('121212')
    lf.value.render();
  }
  lf.value.on('selection:selected,node:click,blank:click,edge:click', () => {
    nextTick(() => {
      const { nodes, edges } = lf.value.getSelectElements();
      activeNodes.value = nodes;
      activeEdges.value = edges;
      getProperty();
    })
  });
}

function dragInNode(type) {
  lf.value.dnd.startDrag({
    type
  });
}


const activeNodes=ref([]);
const activeEdges=ref([]);
const properties=ref({});
// 获取可以进行设置的属性
function getProperty () {
  let property = {}
  const { nodes, edges } = lf.value.getSelectElements();
  nodes.forEach(node => {
    property = { ...property, ...node.properties }
  })
  edges.forEach(edge => {
    property = { ...property, ...edge.properties }
  })
  properties.value = property;
  return property;
}

// 设置样式
function setStyle (item) {
  activeNodes.value.forEach(({ id }) => {
    lf.value.setProperties(id, item)
  });
  activeEdges.value.forEach(({ id }) => {
    lf.value.setProperties(id, item)
  });
  getProperty();
}
// 设置层级
function setZIndex (type) {
  activeNodes.value.forEach(({ id }) => {
    this.lf.setElementZIndex(id, type)
  })
  activeEdges.value.forEach(({ id }) => {
    this.lf.setElementZIndex(id, type)
  })
}




// 选区
const selectionOpened = ref(false);
function selectionSelect() {
  selectionOpened.value = !selectionOpened.value;
  if (this.selectionOpened) {
    lf.value.extension.selectionSelect.openSelectionSelect();
  } else {
    lf.value.extension.selectionSelect.closeSelectionSelect();
  }
}

// 放大缩小
function flowZoom(type) {
  lf.value.zoom(type);
}
// 恢复放大缩小
function flowResetZoom() {
  lf.value.resetZoom();
}
// 上一步
function undo() {
  lf.value.undo();
}
// 下一步
function redo() {
  lf.value.redo();
}

// 下载
function download() {
  lf.value.getSnapshot();
}

function save() {
  const flowData = lf.value.getGraphData();
  instance.api.dataset.flowSave({
    process_data: JSON.stringify(flowData),
    id:experimentUserId.value,
  }).then(() => {
    instance.$Message.success("保存成功");
  });
}

const fullscreenStatus = ref(false);
const flow = ref(null);
function fullscreen() {
  if (fullscreenStatus.value) {
    utils.exitFullscreen();
    fullscreenStatus.value = false;
  } else {
    utils.fullscreen(flow.value);
    fullscreenStatus.value = true;
  }
}

const createExperiment = ()=>{
  let params = {
    experiment_id:flowChartInfo.id
  }
  instance.api.dataset.experimentProcessShow(params).then((res) => {
    instance.$Message.success("创建成功");
    experimentStatus.value = 1;

    initFlowChart();
  });
}



</script>

<style lang="scss" scoped>
.flow {
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;

  .flow-head {
    background-color: white;
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .head-left {
      display: flex;
      align-items: center;

      .head-title {
        font-size: 20px;
        font-weight: bold;
        margin-left: 10px;
        display: flex;
        align-items: center;
        .el-icon-edit{
          font-size: 14px;
          width:80px;
          cursor: pointer;
          flex-shrink: 0;
          margin-left: 10px;
        }
      }
    }
  }
  .program-main{
    height: calc(100% - 70px);
    display: flex;
    overflow: auto;
    margin: 10px 10px 10px 10px;

    .left {
      width: 30%;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      background-color: #FFFFFF;

      .title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .details {
        display: flex;
        color: #666;
        font-size: 16px;
        margin-bottom: 20px;

        .details-item {
          margin-right: 10px;
        }
      }

      //.knowledge-points {
      //  display: flex;
      //  align-items: center;
      //  margin-bottom: 20px;
      //
      //  .knowledge-points-title {
      //    font-size: 16px;
      //    font-weight: bold;
      //    color: #333;
      //  }
      //
      //  .knowledge-points-item {
      //    padding: 2px 5px;
      //    border-radius: 20px;
      //    color: #666;
      //    margin-left: 10px;
      //    background-color: #f7f8f9;
      //    border: 1px solid #e3e3e6
      //  }
      //}

      .describe {
        margin-bottom: 20px;

        .describe-title {
          font-size: 18px;
          color: #333;
          font-weight: bold;
        }

        .describe-desc {
          font-size: 16px;
        }
      }

      //.input {
      //  margin-bottom: 20px;
      //
      //  .input-title {
      //    font-size: 18px;
      //    color: #333;
      //    font-weight: bold;
      //    margin-bottom: 5px;
      //  }
      //
      //  .input-content {
      //    background-color: #f7f8f9;
      //    border-radius: 4px;
      //    padding: 20px;
      //    font-size: 16px;
      //  }
      //}

      //.output {
      //  margin-bottom: 20px;
      //
      //  .output-title {
      //    font-size: 18px;
      //    color: #333;
      //    font-weight: bold;
      //    margin-bottom: 5px;
      //  }
      //
      //  .output-content {
      //    background-color: #f7f8f9;
      //    border-radius: 4px;
      //    padding: 20px;
      //    font-size: 16px;
      //  }
      //}
    }

    .line {
      width: 10px;
      transition: .3s;
      background-color: #f7f8f9;
      cursor: move;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #686868;

      i {
        transform: rotate(90deg);
      }

      &:hover {
        background-color: #dfdfdf;
      }
    }

    .right {
      flex-grow: 1;
      width: 70%;
      display: flex;
      flex-direction: column;
      .right-cont{
        width: 100%;
        height: 100%;
      }
      .right-pre{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

  }
  .flow-main {
    display: flex;
    height: calc(100vh - 52px);

    .main-left {
      width: 230px;
      flex-shrink: 0;
      background-color: white;
      padding: 20px;
      box-sizing: border-box;
      border-top: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      .left-title{
        font-size: 16px;
        font-weight: bold;
      }


      .graphical {
        border-top: 1px solid #e5e5e5;
        margin-top: 20px;
        display: grid;
        padding-top: 20px;
        grid-template-columns: repeat(4, 1fr);
        user-select: none;

        .graphical-item {
          width: 45px;
          height: 45px;
          cursor: pointer;
        }
      }
    }

    .main-right {
      flex-grow: 1;
      background-color: #eaecee;

      .tool-box {
        height: 40px;
        background-color: white;
        border-top: 1px solid #e5e5e5;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 18px;
        padding: 0 20px;
        .el-button{
          margin: 0;
          border-radius: 0;
        }

        .margin {
          margin: 0 10px;
        }
      }

      .flow-wrap {
        height: calc(100% - 35px);
        position: relative;

        .flowChart {
          height: 100%;
        }

        .diagram-panel {
          width: 300px;
          background: #fff;
          height: calc(100% - 5px);
          position: absolute;
          right: 0px;
          top: 0px;
          border: 1px solid #dadce0;
          border-right: none;
          box-sizing: border-box;
        }
      }
    }
  }
  .flow-main-no{
    margin: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
