<template>
<!-- 七边形 -->
  <svg class="svg-node">
    <g transform="translate(0.5,0.5)" style="visibility: visible">
      <polygon
        points="14,0 25.06,5.6 28,18.06 20.02,28 7.7,28 0,18.06 2.94,5.6"
        fill="#ffffff"
        stroke="#000000"
        stroke-width="1.3"
        pointer-events="all"
      ></polygon>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>