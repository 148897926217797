<template>
  <svg class="svg-node">
    <g transform="translate(0.5,0.5)" style="visibility: visible">
      <ellipse
        cx="15.84"
        cy="14.88"
        rx="14.399999999999999"
        ry="9.6"
        fill="#ffffff"
        stroke="#000000"
        stroke-width="1.3"
        pointer-events="all"
      ></ellipse>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>
