<template>
<!-- 五边形 -->
  <svg class="svg-node">
    <g transform="translate(0.5,0.5)" style="visibility: visible">
      <polygon
        points="0,10.6 14,0 28,10.6 22.4,28 5.6,28"
        fill="#ffffff"
        stroke="#000000"
        stroke-width="1.3"
        pointer-events="all"
      ></polygon>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>