<template>
  <svg class="svg-node">
    <g transform="translate(0.5,0.5)" style="visibility: visible">
      <polygon
        points="0,10 20,10 20,0 30,15 20,30 20,20 0,20"
        fill="#ffffff"
        stroke="#000000"
        stroke-width="1.3"
        pointer-events="all"
      ></polygon>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>